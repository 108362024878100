<template>
  <div class="rolemanagement">
    <div class="roleh">
      <span class="title">角色管理</span>
      <el-button v-if="managerType != 0 || getAuth('roleOperate')" type="primary" size="small" style="float:right" @click="createRole()">新增角色</el-button>
    </div>
    <div class="roletb">
      <el-table border :data="tableData" header-cell-class-name="table_header">
        <el-table-column property="role_name" label="角色" width="160"></el-table-column>
        <el-table-column label="权限">
          <template slot-scope="scope">
            {{scope.row.auth_list_text}}
          </template>
        </el-table-column>
        <el-table-column property="statusText" label="状态" width="160"></el-table-column>
        <el-table-column label="操作" v-if="managerType != 0 || getAuth('roleOperate')" width="150" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <rolemana ref="rolemana"/>
  </div>
</template>

<script>
import rolemana from './components/rolemana.vue';
import {allRoleList} from '@/api/role.js'
import { getAuth } from "@/utils/index.js";
export default {
  components: { rolemana },
  name: 'rolemanagement',

  data() {
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      tableData:[]
    };
  },

  mounted() {
    this.initData()
  },
  methods: {
    async initData(){
      const {code,data} = await allRoleList()
      if(code == 200){
        this.tableData = data.map(item => {
          item.statusText = item.status == 1?"正常":"禁用"
          var auth_list_text = []
          item.auth_list.map(val => {
            auth_list_text.push(val.auth_title)
          })
          item.auth_list_text = auth_list_text.join('、')
          return item
        })
      }
    },
    createRole(){
      this.$refs.rolemana.isShow("add")
    },
    edit(row){
      this.$refs.rolemana.isShow("edit",row)
    }
  },
};
</script>

<style lang="scss" scoped>
.rolemanagement{
  .title{
    font-weight: 700;
    color: #515a6e;
    margin-bottom: 30px;
    font-size: 14px;
  }
  .roleh{
    margin-bottom:30px
  }
  .roletb{
    /deep/ .table_header{
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
      font-size: 10px;
      .cell{
        padding: 0px 5px;
      }
    }
  }
}
</style>