<template>
  <div>
    <el-dialog
      :title="title"
      width="520px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="rolemana"
      :before-close="handleClose"
    >
      <el-form :model="form" size="small" label-width="120px">
        <el-form-item label="角色名称：">
          <el-input v-model="form.roleName" clearable placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="角色状态：">
          <el-radio-group v-model="form.authStatus">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择权限：">
          <el-tree
            ref="tree"
            :data="authList"
            :props="defaultProps"
            show-checkbox
            node-key="id"
            highlight-current
            default-expand-all>
          </el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm()" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// createRole，editRole
import {allAuthList} from "@/api/auth.js"
import {createRole,editRole} from "@/api/role.js"
export default {
  name: 'rolemana',

  data() {
    return {
      title:"新增角色",
      dialogFormVisible:false,
      form:{
        roleName:"",
        authStatus:1
      },
      authList:[],
      defaultProps: {
        children: 'child',
        label: 'auth_title'
      },
      authId:[],
      editRoleId:""
    };
  },

  mounted() {
    this.allAuthList()
  },

  methods: {
    async isShow(type,val) {
      this.dialogFormVisible = true
      if(type == "edit"){
        this.title = "修改角色"
        this.editRoleId = val.id
        this.form.editRoleId = val.id
        this.form.roleName = val.role_name
        this.form.authStatus = val.status
        this.$nextTick(() => {
          const arr = []
          val.auth_list.forEach(item => {
            if (!this.$refs.tree.getNode(item.id).childNodes || !this.$refs.tree.getNode(item.id).childNodes.length) {
              arr.push(item)
            }
          })
          this.$refs.tree.setCheckedNodes(arr)
        })
      } else {
        this.title = "新增角色"
        this.editRoleId = ''
      }
      
    },
    async allAuthList(){
      const {code,data} = await allAuthList()
      if(code == 200){
        this.authList = data
      }
    },
    getCheckedKeys(data,keys,key){
      var res = [];
      recursion(data,false);
      return res;
      function recursion(arr,isChild){
        var aCheck = [];
        for(var i = 0; i< arr.length; i++){
          var obj = arr[i];
          aCheck[i] = false;
          if(obj.child){
            aCheck[i] = recursion(obj.child,true) ? true : aCheck[i];
            if(aCheck[i]){
              res.push(obj[key]);
            }
          }
          for(var j =0;j<keys.length;j++){
            if(obj[key] == keys[j]){
              aCheck[i] =true;
              if(res.indexOf(obj[key])==-1){
                res.push(obj[key]);
              }
              break;
            }
          } 
        }
        if(isChild){
          return aCheck.indexOf(true) != -1;
        }
      }
    },
    async confirm(){
      this.authId =  this.getCheckedKeys(this.authList,this.$refs.tree.getCheckedKeys(),'id')
      this.form.authId = this.authId.join(",")
      let res;
          this.editRoleId
          ? (res = await editRole(this.form))
          : (res = await createRole(this.form));
      if(res.code == 200){
        this.close()
        this.$message.success('操作成功')
        this.$parent.initData();
      }
    },
    close(){
      this.dialogFormVisible = false
      this.authId = []
      this.form = {
        roleName:"",
        authStatus:1
      }
      this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys([]);
        })
    },
    handleClose(done){
      done();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .rolemana{
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title{
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__body{
    height: 400px;
    overflow: auto;
  }
  .el-dialog__footer{
    border-top: 1px solid #e8eaec;
  }
}
</style>